import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import LazyLoad from "vanilla-lazyload";

const lazyLoadInstance = new LazyLoad({
  // check out https://github.com/verlok/vanilla-lazyload for possible settings
  elements_selector: ".lazy",
  threshold: 300,
});

import "index.scss";

const application = Application.start();
const context = require.context("./controllers", true, /.js$/);
application.load(definitionsFromContext(context));
