import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["picture", "lightbox"];

  connect() {
    this.setInitialImage();
  }

  changeImage(e) {
    const imageURL = e.currentTarget.dataset.url;

    this.lightboxTarget.src = imageURL;
  }

  setInitialImage() {
    const params = new URLSearchParams(window.location.search);
    let initialImageURL;

    if (params.get("image") != null) {
      const targetElement = this.pictureTargets.find(
        (el) => el.dataset.imageNumber == params.get("image")
      );

      initialImageURL = targetElement.dataset.url;
    } else {
      initialImageURL = this.pictureTarget.dataset.url;
    }

    this.lightboxTarget.src = initialImageURL;
  }
}
